:root {
  --modal-width: 500px;
}

/* * {
  box-sizing: border-box;
} */

#ez-overlay {
  display: none;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 9999999;
}

#ez-popup-message {
  color: black;
}

#ez-popup {
  box-sizing: border-box;
  background-color: #fefefe;
  margin: auto;
  border: 1px solid #888;
  border-radius: 24px;
  width: var(--modal-width);
  height: 695px;
  text-align: center;
  z-index: 9999999;
}

/* The Modal (background) */
.ez-modal {
  /* height: 100%;
  width: 100%; */
}

/* Modal Content */
.ez-modal-content {
  background-color: #fefefe;
  margin: auto;
  position: relative;
  border: 1px solid #888;
  border-radius: 24px;
  width: var(--modal-width);
  height: 695px;
  display: flex;
  justify-content: center;
  flex-direction: row-reverse;
}

/* The Close Button */
.ez-close {
  color: #aaaaaa;
  font-size: 28px;
  font-weight: bold;
  position: absolute;
  top: 4px;
  right: 14px;
}

.ez-close:hover,
.ez-close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

/*iphone 14pro setting*/
#ez-iframe {
  width: 430px;
  height: 100%;
  /* Adjust height as needed */
}

.ezto {
  position: fixed;
  box-sizing: border-box;
  background-color: #fefefe;
  margin: auto;
  border: 1px solid #888;
  border-radius: 24px;
  width: var(--modal-width);
  height: 550px;
  text-align: center;
  z-index: 100;
}

.loaderContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 4rem;
  padding: 0rem 2rem;
  box-sizing: border-box;
}

.ez-loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #1c63eb;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  #ezto {
      height: 100vh;
      width: 100vw;
  }
  .ez-modal-content {
      width: 100vw;
      height: 100vh;
      border-radius: 0;
  }
  #ez-iframe {
      width: 100%;
      height: 100%;
      /* Adjust height as needed */
  }
  #ez-popup {
      width: 100vw;
      height: 100vh;
      border-radius: 0;
  }
}